import React from 'react';
import { Row, Col, media } from 'styled-bootstrap-grid';
import styled, { ThemedStyledProps } from 'styled-components';
import { ResponsiveContainer } from '../styles/styleTemplates';
import { useKeenSlider } from 'keen-slider/react';
import Image from './Image';
// @ts-ignore
import ArrowLeft from '../images/icons/arrowleft.svg';
// @ts-ignore
import ArrowRight from '../images/icons/arrowright.svg';

export default (props) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [pause, setPause] = React.useState(false);
    const timer = React.useRef();
    const [sliderRef, slider] = useKeenSlider({
        slidesPerView: 1,
        loop: true,
        duration: 1500,
        slideChanged(s) {
            setCurrentSlide(s.details().relativeSlide);
        },
        dragStart: () => {
            setPause(true);
        },
        dragEnd: () => {
            setPause(false);
        },
    });

    const nextSlide = () => slider.next();
    const prevSlide = () => slider.prev();

    React.useEffect(() => {
        timer.current = setInterval(() => {
            if (!pause && slider) {
                slider.next();
            }
        }, 5000);
        return () => {
            clearInterval(timer.current);
        };
    }, [pause, slider]);

    return (
        <StyledContainer fluid={true}>
            <StyledRow>
                <RelativeCol noGutter={true} sm={12}>
                    <Arrows>
                        <img src={ArrowLeft} alt="Pfeil links" onClick={() => prevSlide()} />
                        <img src={ArrowRight} alt="Pfeil rechts" onClick={() => nextSlide()} />
                    </Arrows>
                    <Heading>
                        <HeadingText>Wer ist der richtige Designboden-Partner in Ihrer N&auml;he?</HeadingText>
                    </Heading>
                    <Slider ref={sliderRef as React.RefObject<HTMLDivElement>} className="keen-slider" style={{ overflow: 'hidden' }}>
                        {props.header.map((image, idx) => (
                            <div key={idx} className="keen-slider__slide">
                                <HeaderOverlay />
                                <HeaderImage fileName={image.fileName} />
                            </div>
                        ))}
                    </Slider>
                </RelativeCol>
            </StyledRow>
        </StyledContainer>
    );
};

const Slider = styled.div`
    height: 17.5rem;
    ${media.md`
        height: 34.375rem;
    `}
`;

const HeaderImage = styled(Image)`
    height: 17.5rem;
    ${media.md`
        height: auto;
    `}
`;

const StyledContainer = styled(ResponsiveContainer)`
    padding: 0;
    ${media.lg`
        padding: 0 4.375rem;
    `}
`;

const StyledRow = styled(Row)`
    margin: 0;
    ${media.lg`
        margin: 0 -0.938rem;
    `}
`;

const RelativeCol = styled(Col)`
    position: relative;
    z-index: 0;
`;

const HeaderOverlay = styled.div.attrs((props) => ({ style: props.theme.flexCenter }))`
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    background: ${(props) => props.theme.colors.darkOverlay};
`;

const Heading = styled.div.attrs((props) => ({ style: props.theme.flexCenter }))`
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    z-index: 999;
`;

const HeadingText = styled.h1`
    margin-bottom: 5rem;
    line-height: 1.07;
    letter-spacing: 0.368rem;
    text-align: center;
    width: 59.688rem;
    font-size: 1.9rem;
    ${media.sm`
        font-size:  ${(props: ThemedStyledProps<any, any>) => props.theme.fontSizes.md};
        padding: 0 2rem;
    `};
    ${media.md`
        font-size:  ${(props: ThemedStyledProps<any, any>) => props.theme.fontSizes.xl};
    `};
`;

const Arrows = styled.div`
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    z-index: 99;
    top: 50%;
    img {
        cursor: pointer;
        margin: 0 -2rem;
        transition: all 0.3s;
        &:hover {
            transition: all 0.3s;
            margin: 0 -3rem;
        }
    }
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;
