import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import Fade from 'react-reveal/Fade';

export default ({}) => {
    return (
        <Fade bottom>
            <Wrapper fluid={true}>
                <Row>
                    <Col lg={4} md={12}>
                        <Text>
                            <h2>Fühl dich wohl.</h2>
                            <p>Die Vorteile von Designbodenbelägen in Bild und Ton.</p>
                            <a href="https://www.project-floors.com/service/videos/" target="_blank">
                                Video
                            </a>
                        </Text>
                    </Col>
                    <Col lg={8} md={12}>
                        <VideoWrapper>
                            <iframe src="https://youtube.com/embed/CvZ1pwZwaUg?controls=1&origin=https://www.bodengutgemacht.de" />
                        </VideoWrapper>
                    </Col>
                </Row>
            </Wrapper>
        </Fade>
    );
};

const Wrapper = styled(Container)`
    padding: 0 1.375rem;
    @media screen and (max-width: 992px) {
        margin-top: 5rem;
    }
    @media screen and (max-width: 512px) {
        margin-top: 13rem;
    }
    @media screen and (max-width: 485px) {
        margin-top: 16rem;
    }
    @media screen and (max-width: 330px) {
        margin-top: 18rem;
    }
    ${media.lg`
        margin: 9rem 0;
        padding: 0 4.375rem;
    `}
`;

const Text = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    h2 {
        color: ${(props) => props.theme.colors.black};
        margin-bottom: 0;
    }
    p,
    a {
        max-width: 22.3125rem;
        font-size: 1.125rem;
        line-height: 1.67;
        letter-spacing: 0.09375rem;
        color: ${(props) => props.theme.colors.black};
    }
`;

const VideoWrapper = styled.div`
    margin-left: auto;
    height: 22.3125rem;
    cursor: pointer;
    width: 100%;
    margin-top: 2rem;
    iframe {
        height: 100%;
        width: 100%;
    }
    ${media.lg`
        margin-top: 0;
        max-width: 46.375rem;    
    `}
`;
