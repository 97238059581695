import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import GatsbyImage, { GatsbyImageProps } from 'gatsby-image';
import styled from 'styled-components';

const renderImage = (file) => {
    if (!file) {
        return 'Image not found! :(';
    }
    return <StyledGatsbyImage fluid={file.node.childImageSharp.fluid} />;
};

export default ({ fileName }) => {
    return (
        <StaticQuery
            query={graphql`
                query Image {
                    allFile(filter: { relativeDirectory: { eq: "" } }) {
                        edges {
                            node {
                                name
                                ext
                                childImageSharp {
                                    fluid(maxWidth: 2000) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) => {
                const image = data.allFile.edges.find((image) => fileName === `${image.node.name}${image.node.ext}`);
                return renderImage(image);
            }}
        />
    );
};

const StyledGatsbyImage = styled(GatsbyImage)<GatsbyImageProps>`
    height: 100%;
`;
