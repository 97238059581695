import React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Navigation from '../components/Navigation';
import Header from '../components/Header';
import Impressions from '../components/Impressions';
import Search from '../components/Search';
import Tipps from '../components/Tipps';
import PreFooter from '../components/PreFooter';
import Footer from '../components/Footer';
import Video from '../components/Video';
import { getLocationCode, getWebsiteUrlString } from '../lib/util/location';

type DataProps = {
    site: {
        buildTime: string;
    };
};

const Index: React.FC<PageProps<DataProps>> = ({ location }) => {
    const data = useStaticQuery(graphql`
        query data {
            allFeedProjectFloors {
                edges {
                    node {
                        id
                        title
                        content
                    }
                }
            }
            dataYaml {
                tipps {
                    title
                    text
                    image
                    link
                }
                header {
                    fileName
                }
            }
        }
    `);

    const props = {
        ...data.dataYaml,
        impressions: data.allFeedProjectFloors.edges,
    };

    const components = {
        navigation: Navigation,
        header: Header,
        search: Search,
        impressions: Impressions,
        tipps: Tipps,
        video: Video,
        preFooter: PreFooter,
        footer: Footer,
    };

    return (
        <Layout>
            <Seo title={getWebsiteUrlString(getLocationCode(location))} />
            {Object.keys(components).map((component, idx) =>
                React.createElement(components[component], {
                    [component]: props[component],
                    location: getLocationCode(location),
                    key: idx,
                })
            )}
        </Layout>
    );
};

export default Index;
