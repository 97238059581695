import React from 'react';
import { Row, Col, media } from 'styled-bootstrap-grid';
import { FlexContainer, ResponsiveContainer } from '../styles/styleTemplates';
import styled, { ThemedStyledProps } from 'styled-components';
// @ts-ignore
import ArrowLeft from '../images/icons/arrowleft.svg';
// @ts-ignore
import ArrowRight from '../images/icons/arrowright.svg';
import { Impression } from '../Interface/Impression';
import { useKeenSlider } from 'keen-slider/react';
import { NAVLINK_IMPRESSIONEN } from '../constants/constants';

export default (props) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [animateRight, startRight] = React.useState(false);
    const [animateLeft, startLeft] = React.useState(false);
    const [sliderRef, slider] = useKeenSlider({
        slidesPerView: 1.5,
        spacing: 60,
        centered: true,
        breakpoints: {
            '(min-width: 320px)': {
                slidesPerView: 1.5,
                spacing: 135,
            },
            '(min-width: 374px)': {
                slidesPerView: 1.5,
                spacing: 100,
            },
            '(min-width: 613px)': {
                slidesPerView: 2,
                spacing: 20,
                centered: true,
                loop: true,
            },
            '(min-width: 667px)': {
                slidesPerView: 2,
                centered: true,
                loop: true,
            },
            '(min-width: 769px)': {
                slidesPerView: 3,
                centered: false,
            },
            '(min-width: 848px)': {
                slidesPerView: 3,
                centered: false,
                spacing: 120,
            },
            '(min-width: 1024px)': {
                slidesPerView: 3.2,
                centered: false,
            },
            '(min-width: 1366px)': {
                slidesPerView: 4.2,
                spacing: 170,
                centered: false,
            },
        },
        slideChanged(s) {
            setCurrentSlide(s.details().relativeSlide);
        },
    });

    const nextSlide = () => {
        startRight(true);
        slider.next();
        setTimeout(() => startRight(false), 200);
    };
    const prevSlide = () => {
        startLeft(true);
        slider.prev();
        setTimeout(() => startLeft(false), 200);
    };

    const impressions = props.impressions
        .filter((impression) => impression.node.title.length !== 1)
        .map((impression) => {
            return {
                id: impression.node.id,
                title: impression.node.title,
                image: impression.node.content.match('<img[^>]* src=\\"([^\\"]*)\\"[^>]*>')[1].replace('/236x/', '/564x/'),
                link: impression.node.content.match('<a href="([^"]+)')[1],
            };
        });

    return (
        <div style={{ position: 'relative' }}>
            <Arrows>
                <LeftArrow onClick={() => prevSlide()} animate={animateLeft}>
                    <img src={ArrowLeft} alt="pfeil-links" />
                </LeftArrow>
                <RightArrow onClick={() => nextSlide()} animate={animateRight}>
                    <img src={ArrowRight} alt="pfeil-rechts" />
                </RightArrow>
            </Arrows>
            <ImpressionsWrapper fluid={true} id={NAVLINK_IMPRESSIONEN}>
                <Row>
                    <FlexCol sm={12}>
                        <FlexContainer flexDirection="column">
                            <Heading>Inspirationen</Heading>
                        </FlexContainer>
                    </FlexCol>
                </Row>
            </ImpressionsWrapper>
            <SliderWrapper>
                <Row>
                    <Col sm={12}>
                        <div ref={sliderRef as React.RefObject<HTMLDivElement>} className="keen-slider">
                            {impressions.map((impression: Impression) => (
                                <SlideWrapper className="keen-slider__slide" key={impression.id}>
                                    <Slide>
                                        <a
                                            href={impression.link}
                                            target="_blank"
                                            style={{
                                                display: 'block',
                                                height: '100%',
                                            }}>
                                            <SlideOverlay>
                                                <ImpressionText>
                                                    <span>{impression.title}</span>
                                                </ImpressionText>
                                            </SlideOverlay>
                                            <SlideImage src={impression.image} />
                                        </a>
                                    </Slide>
                                </SlideWrapper>
                            ))}
                        </div>
                    </Col>
                </Row>
            </SliderWrapper>
        </div>
    );
};

const ImpressionsWrapper = styled(ResponsiveContainer)`
    margin-top: 3.125rem;
    @media screen and (max-width: 485px) {
        margin-top: 6rem;
    }
`;

const SliderWrapper = styled.div`
    max-width: 90rem;
    padding: 0;
    overflow: hidden;
    ${media.xl`
        padding: 0 1.063rem 0 4.375rem;
    `}
`;

const FlexCol = styled(Col).attrs((props) => ({ style: { ...props.theme.flexBetween } }))``;

const Heading = styled.h2`
    color: ${(props) => props.theme.colors.black};
    margin: 0;
    margin-bottom: 1rem;
`;

const SlideWrapper = styled.div.attrs((props) => ({ style: { ...props.theme.flexCenter, ...props.theme.flexColumn } }))``;

const Slide = styled.div`
    position: relative;
    width: 18.813rem !important;
    height: 100%;
    z-index: 5;
    &:focus {
        outline: none;
    }
    ${media.lg`
        width: 20.75rem !important;
    `}
`;

const ImpressionText = styled.div`
    display: none;
    padding: 1.875rem 1.25rem;
    color: ${(props) => props.theme.colors.impressionText};
    font-size: ${(props) => props.theme.fontSizes.default};
    line-height: 1.375rem;
    letter-spacing: 0.111rem;
    span {
        height: 24.75rem;
        overflow: hidden;
        display: block;
    }
`;

const SlideOverlay = styled.div.attrs((props) => ({ style: { ...props.theme.flexAlignStart } }))<ThemedStyledProps<any, any>>`
    position: absolute;
    height: 100%;
    width: 18.813rem;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 5;
    transition: all 0.2s;
    &:hover {
        background: ${(props) => props.theme.colors.darkOverlay};
        ${ImpressionText} {
            display: block;
            height: 100%;
        }
    }
    &:focus {
        outline: none;
    }
    ${media.lg`
        width: 20.75rem;
    `}
`;

const Arrows = styled.div`
    z-index: 999;
    display: flex;
    position: absolute;
    bottom: -3.2rem;
    right: 1rem;
    ${media.lg`
        right: 4rem;
        top: 1.5rem;
        bottom: unset;
    `}
`;

const Arrow = styled.div`
    cursor: pointer;
    transition: all 0.3s;
    img {
        ${media.lg`
            width: auto;
            height: auto;
        `}
    }
`;

const RightArrow = styled<ThemedStyledProps<any, any>>(Arrow)`
    img {
        transition: all 0.3s;
        transform: ${(props) => (props.animate ? 'translateX(1rem)' : 'none')};
        &:hover {
            transform: translateX(1rem);
        }
        @media screen and (max-width: 1024px) {
            transform: ${(props) => (props.animate ? 'translateX(1rem) !important' : 'none')};
            &:hover {
                transform: none;
            }
        }
    }
`;

const LeftArrow = styled<ThemedStyledProps<any, any>>(Arrow)`
    margin-right: 1rem;
    img {
        transition: all 0.3s;
        transform: ${(props) => (props.animate ? 'translateX(-1rem)' : 'none')};
        &:hover {
            transform: translateX(-1rem);
        }
        @media screen and (max-width: 1024px) {
            transform: ${(props) => (props.animate ? 'translateX(-1rem) !important' : 'none')};
            &:hover {
                transform: none;
            }
        }
    }
    ${media.lg`
        margin-right: 1.813rem;
    `}
`;

const SlideImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 1;
    &:hover {
        background: ${(props) => props.theme.colors.darkOverlay};
    }
`;
