import React, { useState } from 'react';
import styled, { ThemedStyledProps } from 'styled-components';
import { FlexContainer } from '../styles/styleTemplates';
import ArrowLeft from '../images/icons/arrowleft.svg';
import ArrowRight from '../images/icons/arrowright.svg';
import Image from './Image';
import { media } from 'styled-bootstrap-grid';
import { useKeenSlider } from 'keen-slider/react';
import Fade from 'react-reveal/Fade';

export default (props) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const [opacities, setOpacities] = React.useState<number[]>([]);
    const [animateRight, startRight] = React.useState(false);
    const [animateLeft, startLeft] = React.useState(false);

    const [textSliderRef, textSlider] = useKeenSlider({
        vertical: true,
        slidesPerView: 1,
        loop: true,
        resetSlide: true,
        slideChanged(s) {
            setCurrentSlide(s.details().relativeSlide);
        },
        breakpoints: {
            '(max-width: 992px)': {
                vertical: false,
            },
        },
    });
    const [imageSliderRef, imageSlider] = useKeenSlider({
        slidesPerView: 1,
        duration: 2000,
        loop: true,
        resetSlide: true,
        controls: false,
        slideChanged(s) {
            setCurrentSlide(s.details().relativeSlide);
        },
        move(s) {
            const new_opacities = s.details().positions.map((slide) => slide.portion);
            setOpacities(new_opacities);
        },
        breakpoints: {
            '(max-width: 992px)': {
                vertical: true,
            },
        },
    });

    const nextSlide = () => {
        startRight(true);
        textSlider.next();
        imageSlider.next();
        setTimeout(() => startRight(false), 200);
    };

    const prevSlide = () => {
        startLeft(true);
        textSlider.prev();
        imageSlider.prev();
        setTimeout(() => startLeft(false), 200);
    };

    return (
        <React.Fragment>
            <Fade bottom>
                <HeadingWrapper id="tipps">
                    <Heading>Schon gewusst?</Heading>
                </HeadingWrapper>
                <TippContainer>
                    <FlexContainer alignItems="center" height="16.063rem" width="100%">
                        <ImageWrapper>
                            <ImageSlider ref={imageSliderRef as React.RefObject<HTMLDivElement>} className="keen-slider">
                                {props.tipps.map((tipp, idx) => (
                                    <SlideImage key={idx} className="keen-slider__slide" style={{ opacity: opacities[idx] }}>
                                        <Image fileName={tipp.image} />
                                    </SlideImage>
                                ))}
                            </ImageSlider>
                        </ImageWrapper>
                    </FlexContainer>
                    <TippSliderContainer justifyContent="center" alignItems="center">
                        <TippSlider>
                            <div
                                ref={textSliderRef as React.RefObject<HTMLDivElement>}
                                className="keen-slider"
                                style={{
                                    height: '100%',
                                    width: '100%',
                                }}>
                                {props.tipps.map((tipp, idx) => (
                                    <TippSlide key={idx} flexDirection="column" justifyContent="center" className="keen-slider__slide">
                                        <span className="tipp-headline">{tipp.title}</span>
                                        <p>{tipp.text}</p>
                                        <a href={tipp.link} target="_blank">
                                            Kompletten Beitrag lesen
                                        </a>
                                    </TippSlide>
                                ))}
                            </div>
                        </TippSlider>
                        <Arrows>
                            <Arrow onClick={() => prevSlide()} animate={animateLeft}>
                                <img src={ArrowRight} alt="" />
                            </Arrow>
                            <BottomArrow onClick={() => nextSlide()} animate={animateRight}>
                                <img src={ArrowLeft} alt="" />
                            </BottomArrow>
                        </Arrows>
                    </TippSliderContainer>
                </TippContainer>
            </Fade>
        </React.Fragment>
    );
};

const ImageSlider = styled.div`
    height: 100%;
    .slick-list {
        height: 100%;
    }
`;

const SlideImage = styled.div`
    height: 31.25rem;
    width: 39.875rem;
    position: relative;
`;

const HeadingWrapper = styled.div`
    margin-top: 5rem;
    margin-left: 1.438rem;
    max-width: 90rem;
    ${media.lg`
        margin-top: 5.875rem;
        margin-left: 3.375rem;
    `}
`;

const Heading = styled.h2`
    color: ${(props) => props.theme.colors.black};
    margin: 0;
`;

const TippContainer = styled(FlexContainer)`
    max-width: 90rem;
    height: 40.688rem;
    position: relative;
    .keen-slider,
    .keen-slider__slide {
        overflow: hidden;
    }
    @media screen and (max-width: 992px) {
        margin-top: 10rem;
    }
    @media screen and (max-width: 485px) {
        margin-top: 5rem;
    }
`;

const ImageWrapper = styled.div`
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 0 1.438rem;
    ${media.md`
        position: absolute;
        height: 31.25rem;
        width: 39.875rem;
        margin-left: 3.375rem;
        top: 5rem;
    `}
    @media screen and (max-width: 1160px) {
        margin-left: 0.375rem;
    }
    @media screen and (max-width: 1280px) {
        height: 26.25rem;
        width: 32.875rem;
    }
    @media screen and (max-width: 1024px) {
        height: 23.25rem;
        width: 28.875rem;
    }
    @media screen and (max-width: 992px) {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: -4rem;
    }
    @media screen and (max-width: 485px) {
        width: 20.625rem;
        height: 16.063rem;
        padding: 0;
    }
`;

const TippSliderContainer = styled(FlexContainer)`
    background-color: ${(props) => props.theme.colors.pale};
    z-index: 0;
    width: 100%;
    position: absolute;
    right: 0;
    height: 30.938rem;
    top: 12.438rem;
    ${media.md`
        top: 0;
        height: 40.688rem;
        width: 51rem;
    `}
    @media screen and (max-width: 1280px) {
        height: 36.688rem;
    }
    @media screen and (max-width: 1065px) {
        width: 41rem;
    }
    @media screen and (max-width: 1024px) {
        height: auto;
    }
    @media screen and (max-width: 992px) {
        width: 100%;
        top: 12rem;
    }
    @media screen and (max-width: 485px) {
        top: 9rem;
    }
`;

const TippSlider = styled.div`
    height: 32rem;
    padding: 0 1.438rem;
    margin-top: 3.063rem;
    overflow: hidden;
    margin-top: 0;
    width: 33.438rem;
    margin-left: 8rem;
    @media screen and (max-width: 1280px) {
        margin-left: 7rem;
    }
    @media screen and (max-width: 1180px) {
        margin-left: 14rem;
    }
    @media screen and (max-width: 1065px) {
        margin-left: 8rem;
    }
    @media screen and (max-width: 992px) {
        width: 100%;
        margin-left: 0;
    }
    @media screen and (max-width: 507px) {
        height: 36rem;
    }
    @media screen and (max-width: 485px) {
        height: 41rem;
    }
    @media screen and (max-width: 395px) {
        height: 47rem;
    }
    @media screen and (max-width: 330px) {
        height: 50rem;
    }
`;

const TippSlide = styled(FlexContainer)`
    position: relative;
    height: 32rem;
    font-size: ${(props) => props.theme.fontSizes.sm};
    line-height: 1.67;
    letter-spacing: 0.094rem;
    color: ${(props) => props.theme.colors.darkGrey};
    @media screen and (max-width: 1024px) {
        justify-content: center !important;
    }
    @media screen and (max-width: 992px) {
        height: 33rem;
        margin-top: 8rem;
        justify-content: start !important;
    }
    .tipp-headline {
        text-transform: uppercase;
        text-decoration: underline;
    }
    a {
        color: ${(props) => props.theme.colors.darkGrey};
    }
    p {
        overflow: hidden;
        white-space: break-spaces;
        hyphens: auto;
        font-size: 1rem;
    }
`;

const Arrows = styled(FlexContainer)`
    position: absolute;
    top: 5rem;
    right: 1.438rem;
    z-index: 99;
    @media screen and (min-width: 993px) {
        position: relative;
        top: 0;
        right: 0;
        height: 22rem;
        flex-direction: column;
    }
`;

const Arrow = styled.div<ThemedStyledProps<any, any>>`
    cursor: pointer;
    order: 2;
    margin-left: 1rem;
    img {
        transition: all 0.3s;
        transform: ${(props) => (props.animate ? 'translateX(1rem)' : 'none')};
        &:hover {
            transform: translateX(1rem);
        }
        @media screen and (max-width: 1024px) {
            transform: ${(props) => (props.animate ? 'translateX(1rem) !important' : 'none')};
            &:hover {
                transform: none;
            }
        }
    }
    @media screen and (min-width: 993px) {
        transform: rotate(-90deg);
        margin-left: 0;
        order: unset;
    }
`;

const BottomArrow = styled(Arrow)`
    order: 0;
    img {
        transition: all 0.3s;
        ransform: ${(props) => (props.animate ? 'translateX(-1rem)' : 'none')};
        &:hover {
            transform: translateX(-1rem);
        }
        @media screen and (max-width: 1024px) {
            transform: ${(props) => (props.animate ? 'translateX(-1rem) !important' : 'none')};
            &:hover {
                transform: none;
            }
        }
    }
    @media screen and (min-width: 993px) {
        margin-top: 3.813rem;
        transform: rotate(-90deg);
    }
`;
