import React, { useRef, useState } from 'react';
import { Dienstleister } from '../Interface/Dienstleister';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import styled, { keyframes, ThemedStyledProps, ThemeProps } from 'styled-components';
import { Col, media, Row } from 'styled-bootstrap-grid';
import { FlexContainer, ResponsiveContainer } from '../styles/styleTemplates';
// @ts-ignore
import CopySvg from '../images/icons/copy.svg';
import CopyRed from '../images/icons/copyred.svg';
import ArrowBottom from '../images/icons/arrowbottom.svg';
import { AUSTRIA, GERMANY, SWITZERLAND } from '../constants/constants';

const searchAPI = (country, plz) => fetch(`/api/search/${country}/${plz}`);

const debounced = AwesomeDebouncePromise(searchAPI, 400);

export default (props) => {
    const [plz, setPlz] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [hidePlaceholder, setHidePlaceholder] = useState(false);
    const [inputCopyPlaceholderValue, setInputCopyPlaceholderValue] = useState('');
    const [results, setResults] = useState<Dienstleister[]>([]);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [selectedCountry, setSelectedCountry] = useState<string>(props.location);
    const [touched, setTouched] = useState<boolean>(false);

    const input = useRef<HTMLInputElement>(null);
    const inputCopyPlaceholder = useRef<HTMLInputElement>(null);

    const handleInputChange = async (value) => {
        if (value.length <= 5) {
            setPlz(value);
        }
        if (
            (selectedCountry === GERMANY && value.length === 5) ||
            (selectedCountry === AUSTRIA && value.length === 4) ||
            (selectedCountry === SWITZERLAND && value.length === 4)
        ) {
            setLoading(true);
            const call = await debounced(selectedCountry, value);
            const result = await call.json();
            setLoading(false);
            if (result.error) {
                setError(result.error.message);
                setPlz('');
                setHidePlaceholder(false);
                setResults([]);
            }
            if (!result.error) {
                setError('');
                setHidePlaceholder(true);
                setResults(result.result);
            }
        }
    };

    const copyToClipboard = async (dienstleisterId) => {
        const copiedDienstleister = results.find((dienstleister) => dienstleister.id === dienstleisterId) || false;
        if (copiedDienstleister) {
            await setInputCopyPlaceholderValue(
                `${copiedDienstleister.name}, ${copiedDienstleister.strasse} ${copiedDienstleister.hnr}, ${copiedDienstleister.plz} - ${copiedDienstleister.stadt}, ${copiedDienstleister.mail}, ${copiedDienstleister.homepage}`
            );
        }
        inputCopyPlaceholder.current && inputCopyPlaceholder.current.select();
        document.execCommand('copy');
    };

    const countryOptions = [GERMANY, SWITZERLAND];

    const getOptions = () => countryOptions.filter((option) => option !== selectedCountry);

    return (
        <React.Fragment>
            <BoxWrapper>
                <OuterBox>
                    <InnerBox>
                        <Title>Geben Sie Ihre Postleitzahl ein:</Title>
                        <InputWrapper>
                            <CustomSelect onClick={() => setShowOptions(!showOptions)}>
                                <SelectedOptionWrapper>
                                    <img src={ArrowBottom} alt="pfeil-runter" />
                                    <SelectedOption touched={touched}>{selectedCountry}</SelectedOption>
                                </SelectedOptionWrapper>
                                {showOptions && (
                                    <Options>
                                        {getOptions().map((option: string) => (
                                            <CustomOption
                                                key={option}
                                                onClick={() => {
                                                    setTouched(true);
                                                    setSelectedCountry(option);
                                                    setShowOptions(false);
                                                }}>
                                                {option}
                                            </CustomOption>
                                        ))}
                                    </Options>
                                )}
                            </CustomSelect>
                            {loading && <Loader />}
                            {!loading && !hidePlaceholder && plz.length === 0 && (
                                <CustomPlaceholder isError={error.length !== 0} onClick={() => (input.current ? input.current.focus() : null)}>
                                    {error.length !== 0 ? error : 'PLZ'}
                                </CustomPlaceholder>
                            )}
                            <Input
                                value={plz}
                                ref={input}
                                type="number"
                                onChange={(e) => handleInputChange(e.target.value)}
                                onFocus={() => setHidePlaceholder(true)}
                                onBlur={() => setHidePlaceholder(false)}
                            />
                        </InputWrapper>
                    </InnerBox>
                </OuterBox>
            </BoxWrapper>
            <SearchResultsWrapper fluid={true} isSearched={results.length !== 0}>
                <Row justifyContent="center">
                    {results.map((dienstleister: Dienstleister) => (
                        <ResultCardCol sm={12} md={4} lg={3} key={dienstleister.id}>
                            <ResultCard>
                                <CardTitleWrapper>
                                    <CardTitle>{dienstleister.name}</CardTitle>
                                    <CardSubTitle>{dienstleister.stadt}</CardSubTitle>
                                </CardTitleWrapper>
                                <ResultAddresWrapper>
                                    <FlexContainer>
                                        <FlexContainer flexDirection="column" justifyContent="flex-end">
                                            {dienstleister.mail && <ListItemPrefix>E</ListItemPrefix>}
                                            {dienstleister.tel && <ListItemPrefix>T</ListItemPrefix>}
                                            {dienstleister.homepage && <ListItemPrefix>W</ListItemPrefix>}
                                        </FlexContainer>
                                        <ResultAddress>
                                            <input type="text" style={{ opacity: 0 }} ref={inputCopyPlaceholder} value={inputCopyPlaceholderValue} />
                                            <li>
                                                {dienstleister.strasse} {dienstleister.hnr}
                                            </li>
                                            <li>
                                                {dienstleister.plz} {dienstleister.stadt} {dienstleister.country}
                                            </li>
                                            {dienstleister.mail && (
                                                <WrappedLi>
                                                    <a href={`mailto:${dienstleister.mail}`}>{dienstleister.mail}</a>
                                                </WrappedLi>
                                            )}
                                            {dienstleister.tel && (
                                                <WrappedLi>
                                                    <a href={`tel:${dienstleister.tel}`}>{dienstleister.tel}</a>
                                                </WrappedLi>
                                            )}
                                            {dienstleister.homepage && (
                                                <WrappedLi>
                                                    <a href={`https://${dienstleister.homepage}`} target="_blank">
                                                        {dienstleister.homepage}
                                                    </a>
                                                </WrappedLi>
                                            )}
                                        </ResultAddress>
                                    </FlexContainer>
                                    <CardLinks>
                                        <CopyButton onClick={() => copyToClipboard(dienstleister.id)} title="Addresse kopieren">
                                            <img src={CopySvg} alt="" className="copy" />
                                            <img src={CopyRed} alt="" className="copy-red" />
                                        </CopyButton>
                                    </CardLinks>
                                </ResultAddresWrapper>
                            </ResultCard>
                        </ResultCardCol>
                    ))}
                </Row>
                {results.length !== 0 && (
                    <Row>
                        <InfoText sm={12}>
                            <p>
                                Keinen Händler in Ihrer unmittelbaren Nähe gefunden? <br />
                                Schreiben Sie uns an <a href="mailto:info@bodengutgemacht.de">info@bodengutgemacht.de</a>. Wir finden für Sie eine Lösung.
                            </p>
                        </InfoText>
                    </Row>
                )}
            </SearchResultsWrapper>
        </React.Fragment>
    );
};

const SelectedOptionWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

const SelectedOption = styled.span<{ touched: boolean }>`
    margin-left: 0.5rem;
    color: ${(props) => (props.touched ? props.theme.colors.black : props.theme.colors.grey)};
`;

const CustomSelect = styled.div`
    position: absolute;
    left: 2rem;
    font-family: ${(props) => props.theme.fonts.default};
    color: ${(props) => props.theme.colors.grey};
    text-align: center;
    line-height: 0.8;
    font-size: 1.313rem;
    ${media.lg`
        font-size: ${(props: CustomPlaceholderProps) => (props.isError ? props.theme.fontSizes.sm : props.theme.fontSizes.md)};
    `}
    cursor: pointer;
`;

const Options = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.white};
    top: 1rem;
    left: 1rem;
    padding: 1rem;
    font-size: 2rem;
`;

const CustomOption = styled.span`
    width: 100%;
    text-align: center;
    margin: 0.3rem 0;
    cursor: pointer;
    font-size: 1.313rem;
    &:hover {
        color: ${(props) => props.theme.colors.black};
    }
`;

const InfoText = styled(Col)`
    display: flex;
    justify-content: center;
    p,
    a {
        margin-top: 3.625rem;
        text-align: center;
        max-width: 55.6875rem;
        font-size: 1rem;
        line-height: 1.56;
        letter-spacing: 2.5px;
        text-align: center;
        color: ${(props) => props.theme.colors.darkGrey};
    }
`;

const CopyButton = styled.span`
    .copy-red {
        display: none;
        transition: all 0.3s;
    }
    &:hover {
        .copy {
            display: none;
        }
        .copy-red {
            display: initial;
            .transition: all 0.3s;
            transform: scale(1.2);
        }
    }
`;

const Title = styled.span`
    font-family: ${(props) => props.theme.fonts.default};
    line-height: 2.11;
    letter-spacing: 0.125rem;
    color: ${(props) => props.theme.colors.black};
    font-size: ${(props) => props.theme.fontSizes.default};
    margin-top: 1.375rem;
    ${media.lg`
        margin-top: 0;
        font-size: ${(props) => props.theme.fontSizes.sm};
    `};
`;

const fill = keyframes`
  from {
    width: 0;
    left: 0%;
  }
  to {
    width: 100%:
    left: 200%;
  }
`;

const fillResponsive = keyframes`
  from {
    width: 0;
    left: 2rem;
  }
  to {
    width: 100%:
  }
`;

const Loader = styled.div`
    animation: ${fillResponsive} 1s linear infinite;
    background-color: ${(props) => props.theme.colors.fadedOrange};
    height: 0.313rem;
    position: absolute;
    z-index: 5;
    left: 2rem;
    bottom: 1rem;
    width: 80%;
    ${media.lg`
        animation: ${fill} 1s linear infinite;
        left: 0;
        bottom: -0.156rem;
        width: 100%;
    `}
`;

const BoxWrapper = styled.div.attrs((props) => ({ style: props.theme.flexCenter }))`
    max-width: 90rem;
    width: 100%;
    position: absolute;
    z-index: 2;
`;

const OuterBox = styled.div.attrs((props) => ({ style: props.theme.flexCenter }))`
    background-color: ${(props) => props.theme.colors.white};
    width: 20.625rem;
    height: 8.625rem;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    background: ${(props) => props.theme.colors.lightGrey};
    box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
    border-radius: 0.125rem;
    ${media.lg`
        width: 32.875rem;
        height: 12.438rem;
    `}
    @media (max-width: 340px) {
        width: 100%;
    }
`;

const InnerBox = styled.div.attrs((props) => ({ style: { ...props.theme.flexBetween, ...props.theme.flexColumn } }))`
    height: 7.813rem;
    width: 26.5rem;
`;

const InputWrapper = styled.div.attrs((props) => ({ style: props.theme.flexCenter }))`
    width: 100%;
    height: 100%;
    position: relative;
`;

const Input = styled.input`
    width: 80%;
    height: 50%;
    font-family: ${(props) => props.theme.fonts.h1};
    ${media.lg`
        width: 100%;
        height: 100%;
        color: ${(props: ThemedStyledProps<any, any>) => props.theme.colors.black};
        letter-spacing: 0.125rem;
        line-height: 2.11;
    `};
    border: none;
    background-color: ${(props) => props.theme.colors.lightGrey};
    font-size: ${(props) => props.theme.fontSizes.md};
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    line-height: 0.8;
    letter-spacing: 0.219rem;
    border-radius: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.black};
    &:focus {
        outline: none;
    }
    @media screen and (max-width: 1364px) {
        height: 70%;
        margin-bottom: 14px;
    }
`;

interface CustomPlaceholderProps extends ThemeProps<any> {
    isError?: boolean;
}

const CustomPlaceholder = styled.span<CustomPlaceholderProps>`
    position: absolute;
    font-family: ${(props) => props.theme.fonts.default};
    background-color: ${(props) => props.theme.colors.lightGrey};
    color: ${(props) => props.theme.colors.grey};
    text-align: center;
    line-height: 0.8;
    letter-spacing: 0.313rem;
    font-size: 1.313rem;
    ${media.lg`
        font-size: ${(props: CustomPlaceholderProps) => (props.isError ? props.theme.fontSizes.sm : props.theme.fontSizes.md)};
    `}
`;

interface SearchResultsWrapperProps {
    isSearched: boolean;
}
const SearchResultsWrapper = styled(ResponsiveContainer)<SearchResultsWrapperProps>`
    margin-top: 4.625rem;
    margin-bottom: ${(props) => (props.isSearched ? '7rem' : '0')};
    ${media.lg`
        margin-top: 14.313rem;
    `}
`;

const ResultCardCol = styled(Col).attrs((props) => ({ style: { ...props.theme.flexCenter } }))`
    margin-top: 1.875rem;
    ${media.lg`
        margin-top: 0;
    `}
`;

const ResultCard = styled.div.attrs((props) => ({ style: { ...props.theme.flexBetween, ...props.theme.flexColumn } }))`
    background-color: ${(props) => props.theme.colors.fadedOrange};
    position: relative;
    height: 100%;
    width: 20.625rem;
`;

const CardTitleWrapper = styled.div`
    padding: 2.125rem 10px 2.125rem 2.125rem;
    margin-right: auto;
    display: flex;
    flex-direction: column;
`;

const CardTitle = styled.span`
    font-size: 1.563rem;
    color: ${(props) => props.theme.colors.white};
    letter-spacing: 0.156rem;
    line-height: 1.875rem;
`;

const CardSubTitle = styled(CardTitle)`
    color: #cd5e1e;
`;

const ResultAddresWrapper = styled.div`
    padding: 1.125rem;
    width: 100%;
`;

const WrappedLi = styled.li`
    width: 240px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
`;

const ResultAddress = styled.ul`
    list-style: none;
    margin: 0 0 0 0.313rem;
    padding: 0;
    li {
        color: ${(props) => props.theme.colors.white};
        font-size: 1rem;
        line-height: 1.563rem;
        letter-spacing: 0.156rem;
        span {
            margin-left: 0.5rem;
        }
    }
    a {
        color: ${(props) => props.theme.colors.white};
        text-decoration: underline;
    }
`;

const CardLinks = styled.div.attrs((props) => ({ style: { ...props.theme.flexEnd } }))`
    span {
        cursor: pointer;
    }
    width: 100%;
`;

const ListItemPrefix = styled.span`
    text-align: left;
    line-height: 1.56;
    letter-spacing: 0.156rem;
    color: #cd5e1e;
`;
